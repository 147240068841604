import { createContext, useContext, useEffect, useMemo } from "react";

import { usePianoEnv } from "@sciam/piano/react";
import { defaultAppSlug } from "~features/chargebee/environment";
import { useFlags } from "./use-flags";

interface EnvironmentContextType {
  auth: {
    provider: "auth0";
    environment: string;
  };
  entitlements: {
    provider: "piano" | "chargebee";
    environment: string;
  };
  esp: {
    provider: "sendgrid" | "klaviyo";
    environment: string;
  };
}

const EnvironmentContext = createContext<EnvironmentContextType | undefined>(undefined);

export const ProvideEnvironment = ({ children }: { children: React.ReactNode }) => {
  // Flags passed from server, set via query param, or persisted in local storage
  const flags = useFlags();

  const auth0AppSlug =
    (typeof flags?.auth0 === "string" && flags.auth0) || import.meta.env.PUBLIC_AUTH0_APP;

  const pianoEnv = usePianoEnv();
  const pianoEnvSlug = pianoEnv?.slug || "";

  const chargebeeFlag = flags?.chargebee || true;
  const chargebeeAppSlug = (typeof chargebeeFlag === "string" && chargebeeFlag) || defaultAppSlug;

  const environment: EnvironmentContextType = useMemo(
    () => ({
      auth: {
        provider: "auth0",
        // If the auth0 flag has an argument (e.g. flag=auth0:test), use that as the environment
        environment: auth0AppSlug,
      },
      entitlements: chargebeeFlag
        ? {
            provider: "chargebee",
            environment: chargebeeAppSlug,
          }
        : {
            provider: "piano",
            environment: pianoEnvSlug,
          },
      esp: {
        provider: "sendgrid",
        environment: "prod",
      },
    }),
    // Prevent unnecessary re-renders on everything that uses the environment context
    [auth0AppSlug, pianoEnvSlug, chargebeeFlag, chargebeeAppSlug],
  );

  // Expose the environment to the window object for access outside of React
  useEffect(() => {
    window.__sa_env = environment;
  }, [environment]);

  return <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>;
};

export const useEnvironment = (): EnvironmentContextType => {
  return useContext(EnvironmentContext);
};
