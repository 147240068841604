import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { Flags, getClientSideFlags, syncClientSideFlags } from "@sciam/shared";
import { usePageData } from "./use-page";

const FlagsContext = createContext<Flags>({});

export const ProvideFlags = ({ children }) => {
  // @TODO: This doesn't exist in SSR yet.
  // For SSR support, we'll need to pass this property from renderPage() in the Express app, derived from the request object and/or cookies.
  const { initialFlags = null } = usePageData();
  const [flags, setFlags] = useState(initialFlags as Flags | null);

  useEffect(() => {
    syncClientSideFlags();
    // Combine initial flags with client-side flags
    setFlags({ ...initialFlags, ...getClientSideFlags() });
  }, []);

  const value = useMemo(() => flags, [flags]);

  return <FlagsContext.Provider value={value} children={children} />;
};

/**
 * Get the flags object from the page context
 * @param immediate Bypass the context and return the initial flags directly. **THIS WILL BREAK HYDRATION** if not used carefully.
 */
export const useFlags = (immediate = false) => {
  const flagsFromContext = useContext(FlagsContext);
  const { initialFlags } = usePageData();
  const [immediateFlags, _setImmediateFlags] = useState<Flags | null>(() => {
    if (!immediate || typeof window === "undefined") return null;
    // Bypass the context and hydration process by returning the initial flags directly
        // This should only be used in cases where the value is needed before hydration but not necessary for rendering
        // e.g., setting external configuration or initializing a library
          syncClientSideFlags();
          return { ...(initialFlags || {}), ...(getClientSideFlags() || {}) };
  });

  return immediate ? immediateFlags : flagsFromContext;
};
