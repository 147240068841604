import { useCallback } from "react";

import { useAuth0 } from "@auth0/auth0-react";

export function useAuth() {
  // The auth environment can is derived from
  // 1. the build environment(e.g. local, staging, betas, or production)
  // 2. an overridden Piano environment, e.g. ?debug=piano-preprod
  // 3. explicit override via flag parameter, e.g. ?flag=auth0
  const auth0 = useAuth0();

  const authState = {
    isLoggedIn: auth0.isAuthenticated,
    isLoading: auth0.isLoading,
    isMaybeLoggedIn: auth0.isAuthenticated,
    user: auth0.user && {
      // Backwards compatibility with Piano user object
      uid: auth0.user["https://sciam.com/user_id"],

      // Prefer the namespaced claims over given/family (which might not be set)
      firstName: auth0.user["https://sciam.com/first_name"] || auth0.user.given_name,
      lastName: auth0.user["https://sciam.com/last_name"] || auth0.user.family_name,

      ...auth0.user,
    },
  };

  /**
   * Login the user
   * @param {boolean|string} redirect Whether to redirect to the login page or open a popup
   */
  const login = useCallback(
    /**
     * Login the user
     * @param {boolean|string} redirect Whether to redirect to the login page or open a popup
     */
    (redirect = true) => {
      // This will change current window to the login page and redirect back to the current page (up to around 5 redirects total)
      if (redirect) {
        const returnTo =
          typeof redirect === "string"
            ? redirect
            : window.location.pathname + window.location.search;
        return auth0.loginWithRedirect({
          appState: { returnTo },
          authorizationParams: { screen_hint: "login" },
        });
      }

      // Opens a popup window and closes when authenticated, maintaining page state without a reload
      return auth0.loginWithPopup({ authorizationParams: { screen_hint: "login" } });
    },
    [auth0.loginWithRedirect],
  );

  /**
   * Register the user
   * @param {boolean|string} redirect Whether to redirect to the register page or open a popup
   */
  const register = useCallback(
    /**
     * Register the user
     * @param {boolean|string} redirect Whether to redirect to the register page or open a popup
     */
    (redirect = true) => {
      if (redirect) {
        const returnTo =
          typeof redirect === "string"
            ? redirect
            : window.location.pathname + window.location.search;
        return auth0.loginWithRedirect({
          appState: { returnTo },
          authorizationParams: {
            screen_hint: "signup",
          },
        });
      }

      return auth0.loginWithPopup({ authorizationParams: { screen_hint: "signup" } });
    },
    [auth0.loginWithRedirect],
  );

  /**
   * Logout
   *
   * For Auth0, this will redirect to the Auth0 logout page and then back to the current page
   */
  const logout = useCallback(
    (redirect = true) => {
      const returnToRaw =
        typeof redirect === "string" ? redirect : window.location.pathname + window.location.search;

      // Account pages require auth, so in this case, we want to redirect to the homepage
      const accountRegex = /\/account\/(.*$)/;
      const returnTo = returnToRaw.replace(accountRegex, "/");

      return auth0.logout({
        client_id: import.meta.env.PUBLIC_AUTH0_CLIENT_ID,
        logoutParams: {
          // Go to our dedicated logout page, which will clear the Piano JWT, clean up Piano JS SDK's
          // client-side state, then redirect back to the current page
          returnTo: window.location.origin + "/logout/?returnTo=" + returnTo,
        },
      });
    },
    [auth0.logout],
  );

  return {
    // Authentication State
    ...authState,

    // Authentication Actions
    login,
    register,
    logout,
  };
}

let sentryReported = false;

/**
 * @deprecated This was made irrelevant by the SciAm JWT flow
 * @TODO: Remove all instances of this hook
 */
export function useAuthSyncError() {
  return {
    hasError: false,
    hasWarning: false,
    errors: [],
  };
}

export default useAuth;
