export interface InjectOptions {
  /** Params to be injected. Values **must** be strings or null */
  params: Record<string, string | null>;
  url?: string | URL;
  options?: RequestInit;
}

const HOST =
  typeof window === "undefined" ? "https://www.scientificamerican.com" : window.location.origin;

/**
 * Injects params into the request URL or body depending on the request's content type.
 */
export function injectParamsInRequest({ params, url = "", options = {} }: InjectOptions) {
  const contentType = options.headers?.["Content-Type"];
  let body = options.body;

  // Inject params into URL-encoded payloads
  // Un-stringify URLSearchParams objects
  if (contentType === "application/x-www-form-urlencoded" && typeof body === "string") {
    body = new URLSearchParams(body);
  }

  // Inject params into URLSearchParams and FormData objects
  if (body instanceof URLSearchParams || body instanceof FormData) {
    for (const [key, value] of Object.entries(params)) {
      if (value === undefined) {
        continue;
      } else if (value === null) {
        body.delete(key);
      } else {
        body.append(key, value);
      }
    }
  }

  // If all else fails (JSON content type, no content type, body is a data stream, etc.),
  // rewrite the the URL with injected params
  else {
    const urlParams = new URLSearchParams(params);
    url = new URL(url, HOST);
    for (const [key, value] of urlParams) {
      if (value === undefined) {
        continue;
      } else if (value === null) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.append(key, value);
      }
    }
  }

  return { body, url };
}

export default injectParamsInRequest;
